const apiUrl = 'https://connect.facebook.net/en_US/sdk.js';

const installClient = () => new Promise(resolve => {
  if (document.getElementById('facebook-jssdk')) {
    resolve();
    return;
  }

  const js = document.createElement('script');
  js.id = 'facebook-jssdk';
  js.src = apiUrl;
  js.onreadystatechange = js.onload = () => {
    if (!js.readyState || /loaded|compvare/.test(js.readyState)) {
      setTimeout(() => {
        resolve();
      }, 500);
    }
  };

  const fjs = document.getElementsByTagName('script')[0];
  fjs.parentNode.insertBefore(js, fjs);
});

const initClient = config => new Promise((resolve, reject) => {
  window.FB.init({
    appId: config.app_id,
    cookie: false,
    xfbml: true,
    version: config.api_version,
  });

  resolve(window.FB);
});

let FBInstance = null;

export default {
  name: 'facebook',
  init: config => new Promise((resolve, reject) => {
    installClient()
      .then(() => initClient(config || {}))
      .then(instance => {
        FBInstance = instance;
      })
      .then(() => resolve(FBInstance))
      .catch(() => reject(Error('Failed initialization')))
    ;
  }),
  getAuthorizationCode: () => new Promise((resolve, reject) => {
    FBInstance.getLoginStatus(response => {
      if (!response) {
        reject(Error('Can\'t get informations from facebook'));

        return;
      }

      const status = response.status;
      if (status === 'connected' && response.authResponse) {
        resolve(response.authResponse.accessToken);

        return;
      }

      FBInstance.login(response => {
        if (status === 'connected' && response.authResponse) {
          resolve(response.authResponse.accessToken);

          return;
        }

        reject(Error('Can\'t get informations from facebook'));
      }, { scope: 'email' });
    });
  }),
};
