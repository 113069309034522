import $axios from '@/libs/OAuth2/services/axios.js';
import md5 from 'md5';

const emailToMd5 = email => md5(email.toString().toLowerCase());
const defaultPerPage = 15;

export default {
  // Uploader
  uploadFile: file => $axios.post('/temporaryFiles', { file }),

  // GEO
  autocompleteTowns: (search, cancelToken) => $axios.get(`/geo/towns/autocomplete?search=${encodeURI(search)}`, { cancelToken }),
  getTown: id => $axios.get(`/geo/towns/${id}`),

  // Change password
  getChangePasswordToken: email => $axios.post(`/users/${emailToMd5(email)}/changePasswordTokens`),
  changePasswordWithToken: (email, token, password) => $axios.post(`/users/${email}/password`, { token, password }),

  // Registration
  signup: user => $axios.post('/users', user),
  activateUser: (email, token) => $axios.post(`/users/${email}/activation`, { token }),

  // Users
  listUsers: (page = 1, perPage = defaultPerPage, params = {}) => $axios.get('/users', {
    params: {
      ...params,
      page,
      perPage,
    },
  }),
  fetchUser: id => $axios.get(`/users/${id}`),
  forceUserActivation: id => $axios.post(`/users/${id}/activation`),
  resendUserActivationEmail: id => $axios.post(`/users/${id}/activation-email`),
  banUser: id => $axios.delete(`/users/${id}`),
  updateUser: (id, user) => $axios.put(`/users/${id}`, user),

  // My profile
  getMyProfile: () => $axios.get('/profile'),
  updateMyProfile: user => $axios.put('/profile', user),
  changeMyPassword: (password, newPassword) => $axios.post('/profile/password', { password, newPassword }),

  // Merchant's shop
  createMyShop: shop => $axios.post('/profile/shop', shop),
  getMyShop: () => $axios.get('/profile/shop'),
  getMyShopWebSettings: () => $axios.get('/profile/shop/web'),
  updateMyShopWebSettings: settings => $axios.put('/profile/shop/web', settings),
  getMyShopInvoiceSettings: () => $axios.get('/profile/shop/invoice'),
  updateMyShopInvoiceSettings: settings => $axios.put('/profile/shop/invoice', settings),
  getMyShopExportSettings: () => $axios.get('/profile/shop/export'),
  updateMyShopExportSettings: settings => $axios.put('/profile/shop/export', settings),
  payShopActivation: payload => $axios.post('/profile/shop/activation', payload),

  // Shops
  listShops: (page = null, perPage = null, params = {}) => $axios.get('/shops', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  fetchShop: id => $axios.get(`/shops/${id}`),
  banShop: id => $axios.delete(`/shops/${id}`),
  updateShop: (id, item) => $axios.put(`/shops/${id}`, item),

  // Items
  listItems: (page = null, perPage = null, params = {}) => $axios.get('/items', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  createItem: item => $axios.post('/items', item),
  fetchItems: () => $axios.get('/items', {
    params: {
      all: true,
    },
  }),
  fetchItem: id => $axios.get(`/items/${id}`),
  removeItem: id => $axios.delete(`/items/${id}/sellability`),
  restoreItem: id => $axios.post(`/items/${id}/sellability`),
  duplicateItem: id => $axios.post(`/items/${id}/clone`),
  updateItem: (id, item) => $axios.put(`/items/${id}`, item),

  // Categories
  listCategories: (params = {}) => $axios.get('/categories', { params }),
  fetchCategories: (params = {}) => $axios.get('/categories', { params: { ...params, showAll: true } }),
  createCategory: category => $axios.post('/categories', category),
  fetchCategory: id => $axios.get(`/categories/${id}`),
  removeCategory: id => $axios.delete(`/categories/${id}`),
  updateCategory: (id, category) => $axios.put(`/categories/${id}`, category),

  // Pricings
  listPricings: (params = {}, cancelToken = null) => $axios.get('/pricings', { params, cancelToken }),
  createPricing: pricing => $axios.post('/pricings', pricing),
  fetchPricing: id => $axios.get(`/pricings/${id}`),
  removePricing: id => $axios.delete(`/pricings/${id}`),
  updatePricing: (id, pricing) => $axios.put(`/pricings/${id}`, pricing),

  // Purchases
  listPurchases: (page = 1, perPage = defaultPerPage, params = {}) => $axios.get('/purchases', {
    params: {
      ...params,
      page,
      perPage,
    },
  }),
  fetchPurchase: id => $axios.get(`/purchases/${id}`),
  acceptPurchase: id => $axios.post(`/purchases/${id}/payment`),
  rejectPurchase: id => $axios.delete(`/purchases/${id}`),
  redeemPurchase: id => $axios.post(`/purchases/${id}/redemption`),
  exportPurchases: (format = 'csv', params = {}) => {
    params = { ...{ exportFormat: format }, ...(params || {}), ...{ all: true, export: true } };

    return $axios.get('/purchases', {
      params,
    });
  },
};
