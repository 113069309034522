export default {
  data () {
    return {
      checkGuards: (newRoute) => {},
    };
  },
  watch: {
    $route (newRoute) {
      this.checkGuards(newRoute);
    },
  },
};
