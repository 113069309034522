<template>
  <BDropdown class="btn btn-account" right block menu-class="shadow-sm" :text="name">
    <slot name="actions" :actions="actions">
      <template v-for="action in actions">
        <slot :name="'action-' + action.key" :action="action">
          <BDropdownItem v-if="action.link" :key="action.key" :to="action.link" :active="$route.name === action.link.name">
            <slot :name="'action-link-label-' + action.key" :action="action" :label="action.label" :link="action.link">
              {{ action.label }}
            </slot>
          </BDropdownItem>
          <BDropdownItem v-else-if="action.event" :key="action.key" :active="activeAction === action.event" @click.prevent="actionSelected(action.event)">
            <slot :name="'action-content-' + action.key" :action="action" :label="action.label" :event="action.event">
              {{ action.label }}
            </slot>
          </BDropdownItem>
          <BDropdownItem v-else :key="action.key">
            <slot :name="'action-content-' + action.key" :action="action">
              {{ action.label }}
            </slot>
          </BDropdownItem>
        </slot>
      </template>
    </slot>
  </BDropdown>
</template>

<script>

import { BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    actions: {
      type: Array,
      default: () => [
        { key: 'profile', link: { name: 'profile' }, label: 'Profilo' },
        { key: 'change-password', link: { name: 'change-password' }, label: 'Cambia password' },
        { key: 'logout', link: { name: 'logout' }, label: 'Logout' },
      ],
    },
    activeAction: {
      type: String,
      default: null,
    },
  },
  computed: {
    name () {
      return this.label || this.$oauth2?.me?.name || 'Utente';
    },
  },
  methods: {
    actionSelected (actionName) {
      this.$emit('action-selected', actionName);
    },
  },
};

</script>

<style lang="scss">

$account-dropdown-toggle-bg-color: transparent !default;
$account-dropdown-bg-color: white;

.btn.btn-account.dropdown {
  padding: 0 !important;

  .dropdown-toggle {
    background: $account-dropdown-toggle-bg-color;
    border: none;
    padding: 4px 15px 4px 30px;
    font-weight: bold;

    &::after {
      margin-left: 15px;
      border-top-width: 0.5em;
      border-right-width: 0.3em;
      border-left-width: 0.3em;
      vertical-align: 0.10em;
    }
  }

  .dropdown-menu {
    min-width: 100%;
    border: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: $account-dropdown-bg-color;

    .dropdown-item {
      text-align: right;

      &.active,
      &:active {
        background: transparent;
        color: inherit;
        font-weight: bold;
      }

      &.hover,
      &:hover {
        background: transparent;
      }
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
}

</style>
