import rolesWithOneRolePerUserMixin from '@/libs/OAuth2/mixins/rolesWithOneRolePerUser.js';

export default {
  mixins: [rolesWithOneRolePerUserMixin],
  computed: {
    isAdmin () {
      return this.isRole('admin');
    },
    isMerchant () {
      return this.isRole('merchant');
    },
  },
};
