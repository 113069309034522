<template>
  <div class="layout-public d-flex flex-column">
    <AppHeader class="pt-3 pt-md-5" :isPublic="true" :boxed="false" :noLinkToHome="true" />
    <div class="flex-grow-1 d-flex flex-row public-app-content-wrapper">
      <div class="flex-grow-1 d-flex flex-column">
        <router-view class="page flex-grow-1" />
        <AppFooter class="pt-5 pt-md-2 mb-5" />
      </div>
    </div>
    <ConfirmBox />
  </div>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';

import AppHeader from '@/views/components/Layout/Header.vue';
import AppFooter from '@/views/components/Layout/Footer.vue';

export default {
  mixins: [rolesMixin],
  components: {
    AppHeader,
    AppFooter,
    ConfirmBox: () => import(/* webpackChunkName: "component-confirm-modal" */ '@/views/components/Layout/ConfirmBox.vue'),
  },
};

</script>

<style lang="scss">

$sidebar-size-header-height-mobile: 82px !default;
$lightgrey: #fff !default;

.layout-public {
  min-height: 100%;

  .main-header {
    //  height: $sidebar-size-header-height-mobile;
  }

  .public-app-content-wrapper {
    // height: calc(100vh - (#{$sidebar-size-header-height-mobile + $main-footer-height}));
    overflow: hidden;
  }

  .wrapper {
    background-color: $lightgrey;
  }
}

</style>
