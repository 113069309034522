import { generateLogoutRoute } from '@/libs/OAuth2/routes/logoutRoute.js';
import { generateCatchallRoute } from '@/libs/Router/routes/catchallToRootRoute.js';

const onlyGuests = true;
const onlyLoggedIn = true;

export default [
  /* ACCOUNT EXTERNAL */
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/Login.vue'),
    meta: {
      layout: 'public',
      guards: { onlyGuests },
    },
  },
  {
    path: '/password-reset/:email?',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/PasswordReset.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/password-reset/:email/change-password/:token',
    name: 'password-reset-change-password',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/ResetPasswordChange.vue'),
    meta: {
      layout: 'public',
    },
  },

  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(/* webpackChunkName: "sign-up" */ '@/views/pages/Public/SignUp.vue'),
    meta: {
      layout: 'public',
      guards: { onlyGuests },
    },
  },
  {
    path: '/sign-up/success',
    name: 'sign-up.success',
    component: () => import(/* webpackChunkName: "sign-up" */ '@/views/pages/Public/SignUp.vue'),
    meta: {
      layout: 'public',
      guards: { onlyGuests },
    },
  },
  {
    path: '/profile/:email/activate/:token',
    name: 'profile-activation',
    component: () => import(/* webpackChunkName: "activation" */ '@/views/pages/Public/ActivationLanding.vue'),
    meta: {
      layout: 'public',
      guards: { onlyGuests },
    },
  },

  generateLogoutRoute('login'),

  {
    path: '/',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/pages/Private/Dashboard/Dashboard.vue'),
        meta: {
          guards: { onlyLoggedIn },
        },
      },

      /* ACCOUNT INTERNAL */
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/pages/Private/MyProfile/ShowMyProfile.vue'),
        meta: {
          guards: { onlyLoggedIn },
        },
      },
      {
        path: 'profile/edit',
        name: 'profile.edit',
        component: () => import('@/views/pages/Private/MyProfile/EditMyProfile.vue'),
        meta: {
          guards: { onlyLoggedIn },
        },
      },
      {
        path: 'profile/password',
        name: 'change-password',
        component: () => import('@/views/pages/Private/MyProfile/ChangeMyPassword.vue'),
        meta: {
          guards: { onlyLoggedIn },
        },
      },

      /* MERCHANT'S SHOP */
      {
        path: 'shop/settings',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        meta: {
          guards: { roles: ['merchant'] },
        },
        children: [
          {
            path: 'payActivation',
            name: 'shop.settings.payActivation',
            component: () => import('@/views/pages/Private/Shop/PayActivation.vue'),
          },
          {
            path: 'export',
            name: 'shop.settings.export',
            component: () => import('@/views/pages/Private/Shop/ShopExportSettings.vue'),
          },
          {
            path: 'export/:edit',
            name: 'shop.settings.export.edit',
            component: () => import('@/views/pages/Private/Shop/ShopExportSettings.vue'),
          },
          {
            path: 'invoice',
            name: 'shop.settings.invoice',
            component: () => import('@/views/pages/Private/Shop/ShopInvoiceSettings.vue'),
          },
          {
            path: 'invoice/:edit',
            name: 'shop.settings.invoice.edit',
            component: () => import('@/views/pages/Private/Shop/ShopInvoiceSettings.vue'),
          },
          {
            path: 'web',
            name: 'shop.settings.web',
            component: () => import('@/views/pages/Private/Shop/ShopWebSettings.vue'),
          },
          {
            path: 'web/:edit',
            name: 'shop.settings.web.edit',
            component: () => import('@/views/pages/Private/Shop/ShopWebSettings.vue'),
          },
        ],
      },

      /* SHOPS */
      {
        path: 'shops',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        meta: {
          guards: { roles: ['admin'] },
        },
        children: [
          {
            path: '',
            name: 'shops.list',
            component: () => import('@/views/pages/Private/Shops/ListShops.vue'),
          },
          {
            path: ':id',
            component: () => import('@/views/pages/Private/Shops/ShopFrame.vue'),
            children: [
              {
                path: '',
                name: 'shops.show',
                component: () => import('@/views/pages/Private/Shops/ShowShop.vue'),
              },
              {
                path: 'edit',
                name: 'shops.edit',
                component: () => import('@/views/pages/Private/Shops/EditShop.vue'),
              },
            ],
          },
        ],
      },

      /* ITEMS */
      {
        path: 'items',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        meta: {
          guards: { roles: ['admin', 'merchant'] },
        },
        children: [
          {
            path: '',
            name: 'items.list',
            component: () => import('@/views/pages/Private/Items/ListItems.vue'),
            meta: {
              guards: { roles: ['merchant'] },
            },
          },
          {
            path: 'coming',
            name: 'items.coming',
            component: () => import('@/views/pages/Private/Items/ListComingItems.vue'),
            meta: {
              guards: { roles: ['merchant'] },
            },
          },
          {
            path: 'expired',
            name: 'items.expired',
            component: () => import('@/views/pages/Private/Items/ListExpiredItems.vue'),
            meta: {
              guards: { roles: ['merchant'] },
            },
          },
          {
            path: 'new',
            name: 'items.new',
            component: () => import('@/views/pages/Private/Items/CreateItem.vue'),
            meta: {
              guards: { roles: ['merchant'] },
            },
          },
          {
            path: 'coming/:id',
            component: () => import('@/views/pages/Private/Items/ItemFrame.vue'),
            meta: {
              guards: { roles: ['merchant'] },
            },
            children: [
              {
                path: '',
                name: 'items.coming.show',
                component: () => import('@/views/pages/Private/Items/ShowItem.vue'),
              },
              {
                path: 'edit',
                name: 'items.coming.edit',
                component: () => import('@/views/pages/Private/Items/EditItem.vue'),
              },
            ],
          },
          {
            path: 'expired/:id',
            component: () => import('@/views/pages/Private/Items/ItemFrame.vue'),
            meta: {
              guards: { roles: ['merchant'] },
            },
            children: [
              {
                path: '',
                name: 'items.expired.show',
                component: () => import('@/views/pages/Private/Items/ShowItem.vue'),
              },
              {
                path: 'edit',
                name: 'items.expired.edit',
                component: () => import('@/views/pages/Private/Items/EditItem.vue'),
              },
            ],
          },
          {
            path: ':id',
            component: () => import('@/views/pages/Private/Items/ItemFrame.vue'),
            children: [
              {
                path: '',
                name: 'items.show',
                component: () => import('@/views/pages/Private/Items/ShowItem.vue'),
              },
              {
                path: 'edit',
                name: 'items.edit',
                component: () => import('@/views/pages/Private/Items/EditItem.vue'),
              },
            ],
          },
        ],
      },

      /* CATEGORIES */
      {
        path: 'categories',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        meta: {
          guards: { roles: ['admin'] },
        },
        children: [
          {
            path: '',
            name: 'categories.list',
            component: () => import('@/views/pages/Private/Categories/ListCategories.vue'),
          },
          {
            path: 'new',
            name: 'categories.new',
            component: () => import('@/views/pages/Private/Categories/CreateCategory.vue'),
          },
          {
            path: ':id',
            component: () => import('@/views/pages/Private/Categories/CategoryFrame.vue'),
            children: [
              {
                path: '',
                name: 'categories.show',
                component: () => import('@/views/pages/Private/Categories/ShowCategory.vue'),
              },
              {
                path: 'edit',
                name: 'categories.edit',
                component: () => import('@/views/pages/Private/Categories/EditCategory.vue'),
              },
            ],
          },
        ],
      },

      /* PRICINGS */
      {
        path: 'pricings',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        meta: {
          guards: { roles: ['admin'] },
        },
        children: [
          {
            path: '',
            name: 'pricings.list',
            component: () => import('@/views/pages/Private/Pricings/ListPricings.vue'),
          },
          {
            path: 'new',
            name: 'pricings.new',
            component: () => import('@/views/pages/Private/Pricings/CreatePricing.vue'),
          },
          {
            path: ':id',
            component: () => import('@/views/pages/Private/Pricings/PricingFrame.vue'),
            children: [
              {
                path: '',
                name: 'pricings.show',
                component: () => import('@/views/pages/Private/Pricings/ShowPricing.vue'),
              },
              {
                path: 'edit',
                name: 'pricings.edit',
                component: () => import('@/views/pages/Private/Pricings/EditPricing.vue'),
              },
            ],
          },
        ],
      },

      /* PURCHASES */
      {
        path: 'purchases',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        meta: {
          guards: { roles: ['admin', 'merchant'] },
        },
        children: [
          {
            path: '',
            name: 'purchases.list',
            component: () => import('@/views/pages/Private/Purchases/ListPurchases.vue'),
          },
          {
            path: ':id',
            component: () => import('@/views/pages/Private/Purchases/PurchaseFrame.vue'),
            children: [
              {
                path: '',
                name: 'purchases.show',
                component: () => import('@/views/pages/Private/Purchases/ShowPurchase.vue'),
              },
            ],
          },
        ],
      },

      /* USERS */
      {
        path: 'users',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        meta: {
          guards: { roles: ['admin'] },
        },
        children: [
          {
            path: '',
            name: 'users.list',
            component: () => import('@/views/pages/Private/Users/ListUsers.vue'),
          },
          {
            path: ':id',
            component: () => import('@/views/pages/Private/Users/UserFrame.vue'),
            children: [
              {
                path: '',
                name: 'users.show',
                component: () => import('@/views/pages/Private/Users/ShowUser.vue'),
              },
              {
                path: 'edit',
                name: 'users.edit',
                component: () => import('@/views/pages/Private/Users/EditUser.vue'),
              },
            ],
          },
        ],
      },
    ],
  },

  generateCatchallRoute('/'),
];
