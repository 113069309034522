<template>
  <div class="awl-sidenav">
    <AwlSidebarMenu :links="links" />
  </div>
</template>

<script>

import AwlSidebarMenu from './AwlSidebarMenu.vue';

export default {
  components: {
    AwlSidebarMenu,
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};

</script>

<style lang="scss">

$sidebar-background-color: #eee !default;
$sidebar-box-shadow-color: #1C2023 !default;

.awl-sidenav {
  background-color: $sidebar-background-color;
  box-shadow: 0 0 23px 3px rgba(0, 0, 0, .35);
  box-shadow: 0 0 8px rgba($sidebar-box-shadow-color, 0.3);
  max-width: 300px;
  position: relative;
  width: 100%;
  z-index: 1;
}

</style>
