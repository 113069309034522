/* eslint-disable import/first */
import Vue from 'vue';

/**
 * PLUGIN INITIALIZATION
 */
import UniqueId from 'vue-unique-id';
Vue.use(UniqueId);

import Log from './libs/Log/plugins/Log.js';
Vue.use(Log);

import Config from './libs/Config/plugins/Config.js';
Vue.use(Config);

import ApiVue from './libs/Api/plugins/Api.js';
import endpoints from './config/endpoints.js';
Vue.use(ApiVue, {
  endpoints,
});

import OAuth2Vue from './libs/OAuth2/plugins/OAuth2.js';
import GoogleOAuth2 from './libs/OAuth2/providers/google.js';
import FacebookOAuth2 from './libs/OAuth2/providers/facebook.js';
Vue.use(OAuth2Vue, {
  config: {
    client_id: Config._getService().get('client_id'),
    client_secret: Config._getService().get('client_secret'),
  },
  providers: [
    GoogleOAuth2,
    FacebookOAuth2,
  ],
  configProviders: {
    google: {
      client_id: Config._getService().get('google_client_id'),
    },
    facebook: {
      app_id: Config._getService().get('facebook_app_id'),
      api_version: 'v10.0',
    },
  },
});

import StorageVue from './libs/Storage/plugins/Storage.js';
Vue.use(StorageVue);

import Router from 'vue-router';
Vue.use(Router);

/**
 * APP INITIALIZATION
 */
import App from './views/App.vue';
import routerGenerator from './libs/Router/services/router.js';
import routes from './config/routes.js';
import guards from './config/guards.js';

Vue.config.productionTip = false;

new Vue({
  ...App,
  router: routerGenerator({ routes }, [guards]),
}).$mount('#app');

import './assets/scss/bootstrap.scss';
import './assets/scss/style.scss';
(() => import(/* webpackChunkName: "css-fontawesome" */ './assets/scss/fontawesome.scss'))();
