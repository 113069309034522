<template>
  <header class="main-header" :class="{ 'container-fluid': !boxed, 'container': boxed }">
    <div class="row h-100 align-content-center justify-content-center" :class="{ 'justify-content-lg-center': isPublic, 'justify-content-lg-between': !isPublic }">
      <div class="col-auto d-flex" :class="{ 'pl-0': !isPublic }">
        <div class="py-3" :class="{ 'px-0': boxed, 'px-4': !boxed }">
          <Logo :to="{ name: 'home' }" :disable-link="noLinkToHome">eziCommerce</Logo>
        </div>
      </div>
      <template v-if="!isPublic">
        <div class="col"></div>
        <div class="col-auto d-flex align-items-center d-lg-none pr-0">
          <div>
            <Hamburger class="col-auto d-flex align-items-center d-lg-none px-4"></Hamburger>
          </div>
        </div>
        <div v-if="isLogged" class="col-auto d-none d-lg-flex align-items-center">
          <AccountDropdown :onlyLogout="onlyLogout" />
        </div>
      </template>
    </div>
  </header>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';

import AccountDropdown from '@/views/components/Account/AccountDropdown.vue';
import Logo from '@/views/components/Layout/Logo.vue';
import Hamburger from '@/libs/Sidebar/components/Hamburger/CssHamburger.vue';

export default {
  mixins: [rolesMixin],
  components: {
    Logo,
    AccountDropdown,
    Hamburger,
  },
  props: {
    boxed: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    noLinkToHome: {
      type: Boolean,
      default: false,
    },
    onlyLogout: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss">

$main-header-border-bottom-color: #1C2023 !default;
$main-header-box-shadow-color: #1C2023 !default;

.main-header {
    position: relative;
    z-index: 10;
}

</style>
