export default {
  apiBaseUrl: `${process.env.VUE_APP_EZICOMMERCE_API_BASE_URL || '/api/v1'}`,
  STORAGE_KEY: 'webapp',
  tokenKey: 'webapp-token',
  refreshKey: 'webapp-refresh',
  client_id: `${process.env.VUE_APP_EZICOMMERCE_OAUTH2_CLIENT_ID || 'webapp'}`,
  client_secret: `${process.env.VUE_APP_EZICOMMERCE_OAUTH2_CLIENT_SECRET || '6df6dddd069c6acc615eb12407b70ce'}`,
  env: `${process.env.NODE_ENV} || 'dev'`,
  stripe_token: `${process.env.VUE_APP_EZICOMMERCE_STRIPE_PUBLIC_KEY || ''}`,
  google_client_id: `${process.env.VUE_APP_EZICOMMERCE_GOOGLE_CLIENT_ID || ''}`,
  facebook_app_id: `${process.env.VUE_APP_EZICOMMERCE_FACEBOOK_CLIENT_ID || ''}`,
};
