<template>
  <AccountDropdown class="btn-primary" :label="name" :actions="actions" />
</template>

<script>

import rolesMixin from '@/mixins/roles.js';
import AccountDropdown from '@/libs/OAuth2/components/AccountDropdown.vue';

export default {
  mixins: [rolesMixin],
  components: {
    AccountDropdown,
  },
  props: {
    onlyLogout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actions () {
      const actions = [];

      if (!this.onlyLogout) {
        actions.push({ key: 'change-password', link: { name: 'change-password' }, label: 'Cambia password' });
      }

      actions.push({ key: 'logout', link: { name: 'logout' }, label: 'Logout' });

      return actions;
    },
    name () {
      let text = this.$oauth2?.me?.name;
      text += ' ';
      if (this.isAdmin) {
        text += '(Amministratore)';
      }

      return text;
    },
  },
};

</script>

<style lang="scss">

.btn.btn-account.dropdown {
  .dropdown-toggle {
    color: white;
  }
}

</style>
