const apiUrl = 'https://apis.google.com/js/api.js';

const installClient = () => new Promise(resolve => {
  if (document.getElementById('google-api')) {
    resolve();
    return;
  }

  const js = document.createElement('script');
  js.id = 'google-api';
  js.src = apiUrl;
  js.onreadystatechange = js.onload = () => {
    if (!js.readyState || /loaded|compvare/.test(js.readyState)) {
      setTimeout(() => {
        resolve();
      }, 500);
    }
  };

  document.getElementsByTagName('head')[0].appendChild(js);
});

const initClient = config => new Promise((resolve, reject) => {
  window
    .gapi
    .load('auth2', () => {
      window
        .gapi
        .auth2
        .init(config || {})
        .then(() => resolve(window.gapi.auth2.getAuthInstance()))
        .catch(() => reject(Error('Failed initialization')))
      ;
    })
  ;
});

let GoogleAuthInstance = null;

export default {
  name: 'google',
  init: config => new Promise((resolve, reject) => {
    installClient()
      .then(() => initClient(config || {}))
      .then(instance => {
        GoogleAuthInstance = instance;
      })
      .then(() => resolve(GoogleAuthInstance))
      .catch(e => reject(e))
    ;
  }),
  getAuthorizationCode: () => new Promise((resolve, reject) => {
    GoogleAuthInstance
      .grantOfflineAccess({
        prompt: 'consent',
      })
      .then(resp => resolve(resp.code))
      .catch(error => reject(error))
    ;
  }),
};
