<template>
  <AwlLoader :imagePath="require('@/assets/images/logo.png')" animated fitViewport></AwlLoader>
</template>

<script>

import AwlLoader from '@/libs/Loader/components/AwlBouncingLoader.vue';

export default {
  components: {
    AwlLoader,
  },
};

</script>

<style lang="scss">

.awl-loader {
  background: #fff;
}

</style>
