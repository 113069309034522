<template>
  <ul class="menu-items-list">
    <li :class="{ active: link.active || ($route.name === link.to.name) }" v-for="(link, i) of linksToShow" :key="i">
      <router-link class="link" :to="link.to">
        <i v-if="link.icon" class="icon" :class="link.icon"></i>
        {{ link.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    linksToShow () {
      return this.links.filter(l => !l.hidden);
    },
  },
};

</script>

<style lang="scss">

$sidebar-items-background-color: transparent !default;
$sidebar-items-text-color: #333 !default;
$sidebar-items-hover-background-color: #333 !default;
$sidebar-items-hover-text-color: #eee !default;

.menu-items-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  @include media-breakpoint-down("sm") {
    overflow-y: auto;
  }

  li {
    a {
      background: $sidebar-items-background-color;
      color: $sidebar-items-text-color;
      display: block;
      padding: 20px;
      text-decoration: none;
      transition: background-color 0.3s ease-in-out;

      @include media-breakpoint-down(sm) {
        padding: 10px 12px;
      }

      &:hover {
        background-color: rgba($sidebar-items-hover-background-color, 0.18);
      }

      .icon {
        align-items: flex-end;
        color: $sidebar-items-text-color;
        display: inline-flex;
        justify-content: start;
        width: 24px;
      }
    }

    &.active {
      background: $sidebar-items-hover-background-color;

      a, i {
        color: $sidebar-items-active-text-color;
      }
    }
  }
}

</style>
