<template>
  <router-link v-if="!disableLink && to && to.name !== $route.name" class="logo" :to="{ name: 'home' }" v-close-menu>
    <span class="sr-only"><slot></slot></span>
  </router-link>
  <div v-else class="logo">
    <span class="sr-only"><slot></slot></span>
  </div>
</template>

<script>

import closeMenuDirective from '@/libs/Sidebar/directives/closeMenu.js';

export default {
  directives: {
    closeMenu: closeMenuDirective,
  },
  props: {
    to: {
      type: Object,
      default: null,
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss">

$path-images: '' !default;
$logo-mobile-url: $path-images + '/logo.png' !default;
$logo-mobile-width: 90px !default;
$logo-mobile-height: 50px !default;
$logo-tablet-url: $logo-mobile-url !default;
$logo-tablet-width: $logo-mobile-width !default;
$logo-tablet-height: $logo-mobile-height !default;
$logo-desktop-url: $logo-tablet-url !default;
$logo-desktop-width: $logo-tablet-width !default;
$logo-desktop-height: $logo-tablet-height !default;

.logo {
  display: block;
  width: $logo-mobile-width;
  height: $logo-mobile-height;
  background: url($logo-mobile-url) no-repeat center center;
  background-size: contain;

  @include media-breakpoint-up("md") {
    background-image: url($logo-tablet-url);
    width: $logo-tablet-width;
    height: $logo-tablet-height;
  }

  @include media-breakpoint-up("lg") {
    background-image: url($logo-desktop-url);
    width: $logo-desktop-width;
    height: $logo-desktop-height;
  }
}

</style>
