<template>
  <footer class="container-fluid main-footer d-flex align-items-center justify-content-center">
    <div>© Copyright. eziCommerce</div>
  </footer>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';

export default {
  mixins: [rolesMixin],
};

</script>

<style lang="scss">

$main-footer-bg-color: transparent !default;
$main-footer-text-color: #000 !default;
$main-footer-height: 30px !default;

.main-footer {
  background: $main-footer-bg-color;
  color: $main-footer-text-color;
  font-size: 12px;
  height: $main-footer-height;
  text-align: center;
}

</style>
