<template>
  <div class="layout-complete d-flex flex-column h-100" :class="{ 'shop-disabled': shopDisabled, 'shop-activation-not-paid': !shopDisabled && shopActivationNotPaid }">
    <AppHeader :isPublic="false" :boxed="!readyForRoutes" :onlyLogout="!readyForRoutes" />
    <template v-if="readyForRoutes">
      <div class="flex-grow-1 d-flex flex-row app-content-wrapper" :style="{ paddingBottom: appContentWrapperPaddingBottom }">
        <AwlSidenav class="d-none d-lg-block flex-shrink-0" :links="links" />
        <div class="flex-grow-1 d-flex flex-column app-content">
          <router-view class="page flex-grow-1" />
          <AppFooter />
        </div>
      </div>
      <MobileSidebar v-if="readyForRoutes" class="sidebar mobile-sidebar" :links="links" :marginBottom="paddingBottom" />
      <div v-if="shopDisabled" ref="shop-disabled" class="global-shop-advice shop-disabled">
        <h2 class="global-shop-advice__title">SHOP SOSPESO</h2>
        <p class="global-shop-advice__text mb-0">Attenzione, lo shop è stato sospeso dagli amministratori di eziCommerce.</p>
      </div>
      <div v-else-if="shopActivationNotPaid" ref="shop-activation-not-paid" class="global-shop-advice shop-activation-not-paid">
        <h2 class="global-shop-advice__title">Attiva il tuo e-commerce</h2>
        <div class="d-block d-md-flex justify-content-between">
          <p class="global-shop-advice__text mb-0">
            Attenzione, fino all'attivazione la tua pagina pubblica non sarà visibile.
          </p>
          <div class="text-right">
            <router-link class="btn btn-primary" :to="{ name: 'shop.settings.payActivation' }">Vai al pagamento</router-link>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="flex-grow-1 container-fluid app-content-wrapper">
      <div class="container">
        <template v-if="!shopDataReady">
          <h1>Completa la registrazione</h1>
          <h4>Step 1 di 3: Personalizza il tuo shop</h4>

          <ShopWebForm :feedback.sync="feedback" :onOnboarding="true" @submitForm="completeShopData" />
        </template>
        <template v-else-if="!businessDataReady">
          <h1>Completa la registrazione</h1>
          <h4>Step 2 di 3: Completa i tuoi dati per la fatturazione</h4>

          <ShopInvoiceSettingsForm :feedback.sync="feedback" :onOnboarding="true" @submitForm="completeBusinessData" />
        </template>
        <template v-else-if="feedback !== true">
          <h1>Ci sei quasi!</h1>
          <h4>Step 3 di 3: Aggiungi la prima offerta e inizia a vendere</h4>

          <ItemForm :feedback.sync="feedback" :onOnboarding="true" @submitForm="createFirstItem" />
        </template>
        <template v-else>
          <h1>Operazione conclusa!</h1>
          <h4>Lo shop è configurato correttamente</h4>

          <button class="btn btn-primary mt-5" @click.prevent="completeOnboardWizard">Apri il tuo pannello di controllo</button>
        </template>
      </div>
      <AppFooter class="my-5" />
    </div>
    <ConfirmBox />
  </div>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';

import AppHeader from '@/views/components/Layout/Header.vue';
import AppFooter from '@/views/components/Layout/Footer.vue';
import AwlSidenav from '@/libs/Sidebar/components/AwlSidenav.vue';

export default {
  mixins: [rolesMixin],
  components: {
    AppHeader,
    AppFooter,
    AwlSidenav,
    MobileSidebar: () => import('@/views/components/Layout/MobileSidebar.vue'),
    ShopWebForm: () => import('@/views/components/Form/ShopWebSettingsForm.vue'),
    ShopInvoiceSettingsForm: () => import('@/views/components/Form/ShopInvoiceSettingsForm.vue'),
    ItemForm: () => import('@/views/components/Form/ItemForm.vue'),
    ConfirmBox: () => import(/* webpackChunkName: "component-confirm-modal" */ '@/views/components/Layout/ConfirmBox.vue'),
  },
  data: () => ({
    feedback: null,
    paddingBottom: null,
  }),
  computed: {
    links () {
      return [
        { name: 'Dashboard', to: { name: 'home' }, active: this.$route.path.startsWith('/dashboard') },
        { name: 'Nuova offerta', to: { name: 'items.new' }, active: this.$route.path.startsWith('/items/new'), hidden: this.isAdmin || this.shopDisabled },
        { name: 'Offerte in vendita', to: { name: 'items.list' }, active: this.$route.path.startsWith('/items') && !this.$route.path.startsWith('/items/new') && !this.$route.path.startsWith('/items/coming') && !this.$route.path.startsWith('/items/expired'), hidden: this.isAdmin },
        { name: 'Offerte in partenza', to: { name: 'items.coming' }, active: this.$route.path.startsWith('/items/coming'), hidden: this.isAdmin },
        { name: 'Offerte scadute', to: { name: 'items.expired' }, active: this.$route.path.startsWith('/items/expired'), hidden: this.isAdmin },
        { name: 'Acquisti', to: { name: 'purchases.list' }, active: this.$route.path.startsWith('/purchases') },
        { name: 'Pricings', to: { name: 'pricings.list' }, active: this.$route.path.startsWith('/pricings'), hidden: !this.isAdmin },
        { name: 'Categorie', to: { name: 'categories.list' }, active: this.$route.path.startsWith('/categories'), hidden: !this.isAdmin },
        { name: 'Utenti', to: { name: 'users.list' }, active: this.$route.path.startsWith('/users'), hidden: !this.isAdmin },
        { name: 'Shops', to: { name: 'shops.list' }, active: this.$route.path.startsWith('/shops') || this.$route.path.startsWith('/items'), hidden: !this.isAdmin },
        { name: 'Personalizza vetrina', to: { name: 'shop.settings.web' }, active: this.$route.path.startsWith('/shop/settings/web'), hidden: !this.isMerchant },
        { name: 'Dati di fatturazione', to: { name: 'shop.settings.invoice' }, active: this.$route.path.startsWith('/shop/settings/invoice'), hidden: !this.isMerchant },
        { name: 'Opzioni di esportazione', to: { name: 'shop.settings.export' }, active: this.$route.path.startsWith('/shop/settings/export'), hidden: !this.isMerchant },
        { name: 'Profilo', to: { name: 'profile' }, label: 'Profilo', active: this.$route.path.startsWith('/profile') && !this.$route.path.startsWith('/profile/password') },
      ];
    },
    me () {
      return this.$oauth2?.me;
    },
    shopDisabled () {
      return this.readyForRoutes && this.isMerchant && !this.me?.shop?.enabled;
    },
    shopActivationNotPaid () {
      return this.readyForRoutes && this.isMerchant && !this.me?.shop?.activation_paid_at && this.$route?.name !== 'shop.settings.payActivation';
    },
    readyForRoutes () {
      return this.productsReady;
    },
    shopDataReady () {
      if (!this.me) {
        return false;
      }

      if (this.isAdmin) {
        return true;
      }

      return this.me.shopDataReady;
    },
    businessDataReady () {
      if (!this.me) {
        return false;
      }

      if (this.isAdmin) {
        return true;
      }

      return this.me.businessDataReady;
    },
    productsReady () {
      if (!this.me) {
        return false;
      }

      if (this.isAdmin) {
        return true;
      }

      return this.me.productsReady;
    },
    appContentWrapperPaddingBottom () {
      return (this.paddingBottom === null) ? 0 : (this.paddingBottom + 'px');
    },
  },
  methods: {
    completeShopData (settings) {
      this.feedback = null;

      this.$api.createMyShop(settings)
        .then(() => {
          this.$oauth2.refreshMe();
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    completeBusinessData (settings) {
      this.feedback = null;

      this.$api.updateMyShopInvoiceSettings(settings)
        .then(() => {
          this.$oauth2.refreshMe();
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    createFirstItem (item) {
      this.feedback = null;

      this.$api.createItem(item)
        .then(() => {
          this.feedback = true;
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    completeOnboardWizard () {
      this.$oauth2.refreshMe();
    },
    computePaddingBottom () {
      if (!this.shopDisabled && !this.shopActivationNotPaid) {
        this.paddingBottom = null;

        return;
      }

      if (!this.shopDisabled && this.shopActivationNotPaid && this.$refs['shop-activation-not-paid']) {
        this.paddingBottom = this.$refs['shop-activation-not-paid'].offsetHeight || this.$refs['shop-activation-not-paid'].innerHeight || null;

        return;
      }

      if (this.shopDisabled && this.$refs['shop-disabled']) {
        this.paddingBottom = this.$refs['shop-disabled'].offsetHeight || this.$refs['shop-disabled'].innerHeight || null;

        return;
      }

      this.paddingBottom = 130;
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.computePaddingBottom();
      if (this.$refs['shop-activation-not-paid']) {
        new ResizeObserver(this.computePaddingBottom).observe(this.$refs['shop-activation-not-paid']);
      }
      if (this.$refs['shop-disabled']) {
        new ResizeObserver(this.computePaddingBottom).observe(this.$refs['shop-disabled']);
      }
    });
  },
};

</script>

<style lang="scss">

$sidebar-border-right-color: #1C2023 !default;
$sidebar-size-header-height-mobile: 82px !default;

.sidebar {
  min-width: 200px;
  border-right: 1px solid $sidebar-border-right-color;
}

.sidebar {
  @include media-breakpoint-up("lg") {
    display: none !important;
  }
}

.app-content-wrapper {
  .app-content {
    max-width: 100vw;
  }
}

.global-shop-advice {
  background: $danger;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  padding: 25px;
  color: white;
  font-weight: bold;

  &__title {
    font-size: 1.5rem;

    @include media-breakpoint-up("md") {
      font-size: 2.5rem;
    }
  }

  &__text {
    font-size: 0.7rem;

    @include media-breakpoint-up("md") {
      font-size: 1rem;
    }
  }

  &.shop-activation-not-paid {
    .btn {
      background-color: white;
      color: $danger;
    }
  }
}

</style>
