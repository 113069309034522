<template>
  <Loading v-if="isLoading" class="wrapper centered" />
  <component v-else class="wrapper" :is="layout" />
</template>

<script>

import sidebarEffectorMixin from '@/libs/Sidebar/mixins/sidebarEffector.js';
import checkGuardsMixin from '@/libs/OAuth2/mixins/checkGuards.js';
import checkGuards from '@/security/checkGuards.js';

import Loading from '@/views/components/Layout/Loading.vue';

import AdminLayout from '@/views/layouts/Admin.vue';
import PublicLayout from '@/views/layouts/Public.vue';

export default {
  mixins: [checkGuardsMixin, sidebarEffectorMixin],
  components: {
    Loading,
  },
  data () {
    return {
      loading: true,
      checkGuards: route => checkGuards(route, this.$oauth2, this.$router.replace),
    };
  },
  computed: {
    isLoading () {
      return this.loading || !this.$route?.name;
    },
    layoutName () {
      return this.$route?.meta?.layout || 'panel';
    },
    layout () {
      if (this.layoutName === 'renderless') {
        return null;
      }

      if (this.layoutName === 'public') {
        return PublicLayout;
      }

      return AdminLayout;
    },
  },
  mounted () {
    this.$oauth2
      .getMe()
      .finally(() => {
        this.loading = false;
      })
    ;

    this.$oauth2.addLogoutCallback(() => this.doCloseSidebar);
  },
};

</script>

<style lang="scss">

$min-width: 240px !default;

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: $min-width;
}

</style>
